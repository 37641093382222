import React from "react"
import { Link, graphql } from "gatsby"
import ReactGA from "react-ga"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Scrollspy from "react-scrollspy"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Waypoint } from "react-waypoint"
import Video from "../components/video"
import addToMailchimp from "gatsby-plugin-mailchimp"
import SubmissionForm from "@jukin/submission-form"
class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
      email: "",
      message: "",
      mailchimpdone: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  _handleMailchimpSubmit = e => {
    e.preventDefault()
    console.log("clicked")
    console.log(this.state.email)
    addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          var message =
            "For some reason we are unable to add you as a subscriber at this time. Please try again later."

          if (data.msg.indexOf("already subscribed") !== -1) {
            message = "It looks like you are already subscribed."
          }

          if (data.msg.indexOf("fake or invalid") !== -1) {
            message = "Please enter a valid email address."
          }

          this.setState({
            message: message,
          })
        } else {
          this.setState({
            mailchimpdone: true,
            message: "You are subcribed",
          })
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
  }

  _handleEmailChange = e => {
    this.setState({
      email: e.target.value,
    })
  }

  render() {
    const { data } = this.props
    const { message } = this.state

    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        />
        <div className="pt-md-5 pb-3 mx-auto text-center">
          <nav className={this.state.stickyNav ? "alt primary" : "primary"}>
            <ul>
              <Scrollspy
                items={["about", "newsletter", "submit"]}
                currentClassName="is-current"
                offset={-100}
              >
                <li>
                  <button title="About TiH" onClick={() => scrollTo("#about")}>
                    About
                  </button>
                </li>
                <li>
                  <button
                    title="Subscribe to TiH"
                    onClick={() => scrollTo("#newsletter")}
                  >
                    Newsletter
                  </button>
                </li>
                <li>
                  <button
                    title="Submit Your Video!"
                    onClick={() => scrollTo("#submit")}
                  >
                    Submit
                  </button>
                </li>
              </Scrollspy>
            </ul>
          </nav>
        </div>
        <section id="about">
          <div className="container mx-auto text-center text">
            <Video
              videoSrcURL="https://www.youtube.com/embed/kN4AwtQwUnI"
              videoTitle="Official Music Video on YouTube"
            />
            <p>
              Featuring real stories & moments, captured & told by real people,{" "}
              <em>This Is Happening</em> celebrates the life experiences that
              push us out of our comfort zone & encourage us to consider the
              world in new ways.
            </p>
            <p>
              Join us to explore trending moments, culture, food, events, travel
              & a wide- range of social issues helping us each to grow, learn &
              connect
            </p>

            <h4 className="purple">
              Do you have a story to tell? Share it with us!
            </h4>
            <p className="purple">
              <em>This is Happening:</em> Your Life’s Adventure
            </p>
          </div>
        </section>
        <section id="newsletter">
          <div className="container text">
            <form
              onSubmit={this._handleMailchimpSubmit}
              className="newsletter text-center"
            >
              <h3>GET THE LATEST STORIES FROM THE PEOPLE EXPERIENCING THEM</h3>
              <p className="mb-4">
                Sign up to get notified + explore trending moments, cultural
                issues & events as we highlight the people, places & things that
                inspire, connect & define us!
              </p>

              {this.state.mailchimpdone && (
                <p className="success">
                  {" "}
                  <i className="far fa-check-circle"></i> {message}{" "}
                </p>
              )}

              {!this.state.mailchimpdone && (
                <div className="text-center" role="form">
                  {message && (
                    <p className="error">
                      {" "}
                      <i className="fas fa-exclamation-triangle"></i> {message}{" "}
                    </p>
                  )}
                  <input
                    name="email"
                    type="text"
                    className=""
                    id="staticEmail2"
                    placeholder="email@example.com"
                    onChange={this._handleEmailChange}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary"
                    title="Get the latest stories"
                  >
                    Go
                  </button>
                </div>
              )}
            </form>
          </div>
        </section>
        <section id="submit">
          <div className="container">
            <div className="text-center">
              <h3>
                SHARE YOUR STORY. SUBMIT YOUR VIDEO TO THIS IS HAPPENING!!
              </h3>
              <p>
                <strong>
                  Curious? Learn more about&nbsp;
                  <Link
                    className="purple"
                    to="/faq/"
                    state={{
                      modal: true,
                    }}
                    title="About TiH"
                  >
                    TiH
                  </Link>
                </strong>
              </p>
            </div>
            <SubmissionForm
              site="This is Happening"
              riffUrl={process.env.GATSBY_RIFF_URL}
              submissionSource="thisishappening"
              uploadPrefix={process.env.GATSBY_UPLOAD_PREFIX}
              tos="/terms"
              mailchimpUrl={process.env.GATSBY_MAILCHIMP_URL}
              mailchimpListID={process.env.GATSBY_MAILCHIMP_LIST_ID}
              typeform={process.env.GARSBY_TYPEFORM}
              isNewsletterSignUpRequired={true}
            />
          </div>
        </section>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
